.legal-text * {
    font-size: 14px;
}

.legal-text h2 {
    margin: 10px 0;
    font-weight: bold;
    font-size: 20px;
}

.legal-text h3 {
    margin: 10px 0;
    font-weight: bold;
    font-size: 18px;
}

.legal-text h4 {
    margin: 10px 0;
    text-decoration: underline;
    font-size: 16px;
}

.legal-text p {
    margin: 10px 0;
}

.legal-text ul {
    margin: 10px 0;
    list-style-type: disc;
    margin-left: 15px;
}